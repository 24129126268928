import React from "react";
import * as THREE from "three";

function GridHelper() {
  const size = 100;
  const divisions = 100;
  return(
    // <primitive object={new THREE.GridHelper()} />
    <gridHelper args={[size, divisions, '#000', '#fff']}/>
  );
}

export default GridHelper;