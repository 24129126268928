import React, { useRef, useLayoutEffect } from "react";
import { Stats } from "@react-three/drei";

function StatsPanel({showPanel=0, parentDivId=null}) {
  const statsPanelId = "stats-panel";
  const width = "8rem";
  const height = "3.5rem";
  const parent = useRef(document.createElement('div'))

  useLayoutEffect(() => {
    parent.current.id = statsPanelId;
    const debugPanelsAreaDiv = document.getElementById(parentDivId);
    if (debugPanelsAreaDiv != null) {
      debugPanelsAreaDiv.appendChild(parent.current);
    } else {
      document.body.appendChild(parent.current);
    }

    setTimeout(() => {
      var statsPanel = document.getElementById(statsPanelId);
      if (statsPanel == null) return;
      var canvases = statsPanel.querySelectorAll("canvas");
      // console.log(canvases);
      for (var i=0; i < canvases.length; i++) {
        canvases[i].style.width = width;
        canvases[i].style.height = height;
        canvases[i].style.position = "relative";
        canvases[i].parentNode.style.position = "relative";
      }
    }, 10);

    let currentParent = parent.current;
    return () => currentParent.parentNode?.removeChild(currentParent)
  }, [])

  return <Stats parent={parent} showPanel={showPanel} />
};

export default StatsPanel;