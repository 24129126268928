/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useImperativeHandle, useEffect, useLayoutEffect, useState } from "react"
import * as THREE from "three"
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { useFrame } from "@react-three/fiber"
import { useGLTF, useAnimations } from "@react-three/drei"
import Util from "../lib/Util";
import { Vector3 } from "three"

const Model = React.forwardRef((props, ref) => {
  const {
    onAnimationRunStateChange = (newRunState) => console.log("onAnimationRunStateChange, runState=" + newRunState)
    // runState could be: "running", "paused", "completed"
  } = props;

  const initialPosition = [props.position[0], props.position[1], props.position[2]];
  const initialRotation = [props.rotation[0], props.rotation[1], props.rotation[2]];
  const [hasRenderedFirstTime, setHasRenderedFirstTime] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialActionHasRun, setInitialActionHasRun] = useState(false);
  const groupRef = useRef();
  const skeletonRefDebug = useRef();
  const { nodes, materials, animations } = useGLTF('/DecimatedSkeletonWithAnimationsAndTextures.gltf');
  const { actions, names, mixer } = useAnimations(animations, groupRef);

  const WAVING_ACTION_NAME = "Waving";
  const WALKING_ACTION_NAME = "Walking";

  function anyActionIsRunning() {
    let actionsRunningOrWithNonZeroWeight = Object.values(actions).filter(action => action.isRunning() && action.weight !== 0);
    if (actionsRunningOrWithNonZeroWeight.length > 0) {
      //console.log("actionsRunningOrWithNonZeroWeight are " + actionsRunningOrWithNonZeroWeight.map(a => a.getClip().name + "(isRunning:" + a.isRunning() + ", weight:" + a.weight + ")").join(", "));
    }
    let result = actionsRunningOrWithNonZeroWeight.length > 0;
    return result;
  }
  function anyActionIsPaused() {
    let actionsPaused = Object.values(actions).filter(action => action.paused === true);
    if (actionsPaused.length > 0) {
      // console.log("actionsPaused are " + actionsPaused.map(a => a.getClip().name).join(", "));
    }
    let result = actionsPaused.length > 0;
    return result;
  }
  function anyActionIsPausedOrRunning() {
    let actionsPausedOrRunningWithNonZeroWeight = Object.values(actions).filter(action => (action.isRunning() && action.weight !== 0) || action.paused === true );
    if (actionsPausedOrRunningWithNonZeroWeight.length > 0) {
      // console.log("actionsPausedOrRunningWithNonZeroWeight are " + actionsPausedOrRunningWithNonZeroWeight.map(a => a.getClip().name + "(isRunning:" + a.isRunning() + ", weight:" + a.weight + ", paused:" + a.paused + ")").join(", "));
    }
    let result = actionsPausedOrRunningWithNonZeroWeight.length > 0;
    return result;
  }

  function calculateWalkToToAvoidBoundingBox(
    initialXyz, // array3
    targetXyz, // array3
    bBox /* THREE.Box3 */) {
      if (bBox == null) return targetXyz;
      let boxMaxGroundWidth = Math.max(bBox.max.x - bBox.min.x, bBox.max.z - bBox.min.z);
      // console.log("boxMaxGroundWidth: " + boxMaxGroundWidth);
      // console.log("initialXyz: " + initialXyz + ", targetXyz: " + targetXyz);
      let deltaXyz = [targetXyz[0] - initialXyz[0], targetXyz[1] - initialXyz[1], targetXyz[2] - initialXyz[2]];
      let fullLength = Math.sqrt(deltaXyz[0]*deltaXyz[0] + deltaXyz[1]*deltaXyz[1] + deltaXyz[2]*deltaXyz[2]);
      let ratio = 1.0 - boxMaxGroundWidth/fullLength;
      let newTargetXyz = [initialXyz[0] + ratio*deltaXyz[0], initialXyz[1] + ratio*deltaXyz[1], initialXyz[2] + ratio*deltaXyz[2]]
      return newTargetXyz;
    }

  const doActionInternal = (actionName, ifIdle, loopCount) => {
    if (ifIdle === true && (anyActionIsPausedOrRunning() /* || doingLookAt */) ) {
      return;
    } else {
      Object.values(actions).forEach(action => {
        action.reset().stop();
      });
      clearActionArray();
    }
    onAnimationRunStateChange("running");
    let action = actions[actionName].reset()
    if (loopCount == null) {
      action.setLoop(THREE.LoopOnce);
    } else {
      action.setLoop(THREE.LoopRepeat, loopCount);
    }
    action.clampWhenFinished = true;
    action.fadeIn(0.5).play();
  } // end doActionInternal

  // Returns smallest (signed) angle difference. This should return a value between -Math.PI and Math.PI
  function angleDifference(angle1, angle2 )
  {
      // See: https://stackoverflow.com/questions/1878907/how-can-i-find-the-smallest-difference-between-two-angles-around-a-point/2007279#2007279
      let diff = Math.atan2(Math.sin(angle2-angle1), Math.cos(angle2-angle1))
      diff = diff % (Math.PI*2.0);
      return diff;
  }

  const doWalkToPositionInternal = ([x, y, z], markerBoundingBox, slideDontWalk) => {
    let walkFromWorld = groupRef.current.position;

    let theta = Math.atan2(x - walkFromWorld.x, z - walkFromWorld.z);
    let angleDelta = angleDifference(groupRef.current.rotation.y, theta ); // ensure we rotate thru smallest possible angle to new rotation theta. In range [-PI, PI].
    let rotateTweenDurationMillis = 400/(Math.PI/Math.abs(Math.PI));
    // console.log(`fromAngle: ${Util.radiansDebugString(groupRef.current.rotation.y)}, toAngle: ${Util.radiansDebugString(theta)}, angleDelta: ${Util.radiansDebugString(angleDelta)}`);
    let rotateTween = new TWEEN.Tween(groupRef.current.rotation)
      .to(
        slideDontWalk === true && Util.length(initialPosition, [groupRef.current.position.x, groupRef.current.position.y, groupRef.current.position.z]) < 0.01
        ?
        {
          x: initialRotation[0],
          y: initialRotation[1],
          z: initialRotation[2]
        }
        :
        {
          x: groupRef.current.rotation.x,
          y: groupRef.current.rotation.y + angleDelta,
          z: groupRef.current.rotation.z
        },
        rotateTweenDurationMillis
      );

    let walkTo = calculateWalkToToAvoidBoundingBox(
      [walkFromWorld.x, walkFromWorld.y, walkFromWorld.z],
      [x, y, z],
      markerBoundingBox
    );
    let walkToWorld = new Vector3(walkTo[0], walkTo[1], walkTo[2]);
    let walkingAnimationActionDuration = actions[WALKING_ACTION_NAME].getClip().duration;
    const distance = walkFromWorld.distanceTo(walkToWorld);

    let stepPairsCount = Math.round(distance/1.6/walkingAnimationActionDuration);
    const durationSecs = Math.min(stepPairsCount * walkingAnimationActionDuration, 5); // clamp durationSecs so user is not waiting for pathological case to finish.
    // console.log(walkFromWorld);
    // console.log(walkToWorld);
    // console.log(`walk distance: ${distance}, stepPairsCount: ${stepPairsCount}, durationSecs: ${durationSecs.fixed(2)}`);

    TWEEN.removeAll();
    if (slideDontWalk !== true) {
      doActionInternal(WALKING_ACTION_NAME, false, stepPairsCount);
    }
    rotateTween
      .start()
      .onComplete(() => {
        groupRef.current.rotation.set(groupRef.current.rotation.x, Util.normalizeAngleToMinusPiPi(groupRef.current.rotation.y), groupRef.current.rotation.z); // doesn't change visual rotation, just normalises to [-Pi, Pi]
        let to = {
          x: walkToWorld.x,
          y: walkToWorld.y,
          z: walkToWorld.z,
        };
        let durationMillis = durationSecs * 1000 - rotateTweenDurationMillis;
        durationMillis = Math.max(500, durationMillis); // Don't let duration go negative, make it 1/2 sec minimum so things aren't too janky.
        // console.log(`from: ${Util.vec3DebugString(groupRef.current.position)}, to: ${Util.vec3DebugString(to)}, durationMillis: ${durationMillis}`)
        new TWEEN.Tween(groupRef.current.position)
          .to(to, durationMillis)
          .start()
          .onComplete(() => {
            if (slideDontWalk === true) {
              // console.log(`slideDontWalk, groupRef.current.rotation: ${Util.eulerDebugString(groupRef.current.rotation)}, initialRotation: ${Util.eulerDebugString(initialRotation)}`);
              new TWEEN.Tween(groupRef.current.rotation)
              .to(
                {
                  x: initialRotation[0],
                  y: initialRotation[1],
                  z: initialRotation[2]
                },
                500
              )
              .start();
            } 
          })
        })
  } // end doWalkToPositionInternal

  useImperativeHandle(ref, () => ({
    doResetModelPositionAndRotation: () => {
    doWalkToPositionInternal(initialPosition, null, true, initialRotation);
    },
    doWalkToPosition: ([x, y, z], markerBoundingBox) => {
      doWalkToPositionInternal([x, y, z], markerBoundingBox);
    },
    doAction: (actionName, ifIdle) => doActionInternal(actionName, ifIdle),
    doActions: (actionItems) => {
      if (actionItems == null || actionItems.length === 0) return;
      let newActionArray = actionItems.map((item) => {return {actionName: item.actionName, repeatCount: item.repeatCount}});
      playActionArray(newActionArray);
      onAnimationRunStateChange("running");
    },
    doPauseChange: (newPauseStatus) => {
      if (mixer == null || actions == null || actions.length === 0 ) return;
      // console.log("doPauseChange, newPauseStatus = " + newPauseStatus);
      Object.values(actions).forEach(action => {
        if (newPauseStatus !== true) {
          action.paused = newPauseStatus; // set all actions paused to false
        } else {
          if (action.isRunning()) {
            action.paused = newPauseStatus; // only set running actions paused to true
          }
        }
      });
      if (anyActionIsPaused() && !anyActionIsRunning()) {
        onAnimationRunStateChange("paused");
      }
      if (anyActionIsRunning()) {
        onAnimationRunStateChange("running");
      }
    },
    doLoopChange: (newLoopStatus) => {
      if (mixer == null || actions == null || actions.length === 0 ) return;
      mixer.loopStatus = newLoopStatus;
    },
    doStop: () => {
      if (mixer == null || actions == null || actions.length === 0 ) return;
      Object.values(actions).forEach(action => {
        action.reset().stop();
      });
      clearActionArray();
      onAnimationRunStateChange("completed");
    },
    getActionNames: () => {
      return names;
    },
  }));

  function playActionArray(newActionArray) {

    Object.values(actions).forEach(action => {
      action.reset().stop().reset();
    });

    if (newActionArray == null || newActionArray.length === 0) return;
    
    // Kludge so that we can see current animationArray in mixer event handler
    mixer.actionArrayToPlay = newActionArray;
    mixer.actionArrayCurrentIndex = 0;
    
    let actionName = newActionArray[0].actionName;
    let repeatCount = newActionArray[0].repeatCount;
    // console.log("In playActionArray, playing first action in loop, actionName=" + actionName + ", repeatCount=" + repeatCount);
    let action = actions[actionName].reset().setLoop(THREE.LoopRepeat, repeatCount)
    action.clampWhenFinished = true;
    action.fadeIn(0.5).play(); // start the loop
  }

  function clearActionArray() {
    if (mixer == null) return;
    mixer.actionArrayToPlay = [];
    mixer.actionArrayCurrentIndex = null;
  }

  function handleMixerEventFinished(e) {
    let eventType = e.type; // Either "loop" or "finished"
    if (eventType === "finished") {
      if (mixer.actionArrayToPlay != null 
          && mixer.actionArrayToPlay.length > 0
          && mixer.actionArrayCurrentIndex === mixer.actionArrayToPlay.length - 1
          && mixer.loopStatus === true) {
        // reset user loop to beginning, because user has requested that animation actions are looped.
        mixer.actionArrayCurrentIndex = -1; // will be incremented to 0 below.
      }
      if (mixer.actionArrayToPlay != null && mixer.actionArrayToPlay.length > mixer.actionArrayCurrentIndex + 1) {
        mixer.actionArrayCurrentIndex += 1;
        let actionArray = mixer.actionArrayToPlay;
        let index = mixer.actionArrayCurrentIndex;
        let newActionName = actionArray[index].actionName;
        let repeatCount = actionArray[index].repeatCount;
        // console.log("handleMixerEventFinished, prev action name=" + e.action.getClip().name + ", new action name=" + newActionName + ", repeat count=" + repeatCount + ", mixer.actionArrayCurrentIndex=" + mixer.actionArrayCurrentIndex);
        let newAction = actions[newActionName];
        if (e.action.getClip().name !== newActionName) {
          e.action.fadeOut(0.3);
        }
        newAction.reset().setLoop(THREE.LoopRepeat, repeatCount);
        newAction.clampWhenFinished = true;
        if (e.action.getClip().name !== newActionName) {
          newAction.fadeIn(0.3);
        }
        newAction.play();
      } else {
        // We have finished playing a single action or the last action in a loop.
        // console.log("Finished playing single action or last action in loop, action name = " + e.action._clip.name);
        e.action.fadeOut(0.5).play();
        setTimeout(() => {
          e.action.stop();
        }, 500)
      }
    }
    setTimeout(() => {
      // console.log("anyPaused=" + anyPaused + ", anyRunning=" + anyRunning);
      if (!anyActionIsRunning() && !anyActionIsPaused()) {
        onAnimationRunStateChange("completed");
      }
    }, 700); // Gotcha here. Delay must be longer than any final fadeout. There seems to be no reliable way to determine if no animations are actually in progress. Finished event is thrown before any fadeouts are completed.
  };

  // This is run once on component creation to attach event handler to manimation mixer
  useEffect(() => {
    if (isInitialized) return;
    setIsInitialized(true);
    mixer.addEventListener("finished", handleMixerEventFinished);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  // This is run once on component creation so the skeleton performs a Wave action.
  useEffect(() => {
    if (initialActionHasRun) return;
    setInitialActionHasRun(true);
    doActionInternal(WAVING_ACTION_NAME, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActionHasRun])

  useLayoutEffect(() => {
    if (hasRenderedFirstTime) return;
    setHasRenderedFirstTime(true);
    if (props.handleHasRenderedFirstTime != null) {
      props.handleHasRenderedFirstTime();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRenderedFirstTime]);

  useFrame(() => { // handle walkto
    TWEEN.update();
    // console.log(`skeletonRefPosition: ${Util.vec3DebugString(skeletonRefDebug?.current?.position)}, skeletonRefRotation: ${Util.eulerDebugString(skeletonRefDebug?.current?.rotation)}`);
  })


  return (
    <group ref={groupRef} {...props} /* rotation={modelRotation} */ dispose={null}>
      <group name="Scene">
        <group name="Skeleton" ref={skeletonRefDebug} rotation={[Math.PI/2, 0, 0]} >
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Atlas_Plane050" geometry={nodes.Atlas_Plane050.geometry} material={materials.Cervical_spine} skeleton={nodes.Atlas_Plane050.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Axis_Plane052" geometry={nodes.Axis_Plane052.geometry} material={materials.Cervical_spine} skeleton={nodes.Axis_Plane052.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="C3_Plane053" geometry={nodes.C3_Plane053.geometry} material={materials.Cervical_spine} skeleton={nodes.C3_Plane053.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="C4_Plane054" geometry={nodes.C4_Plane054.geometry} material={materials.Cervical_spine} skeleton={nodes.C4_Plane054.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="C5_Plane055" geometry={nodes.C5_Plane055.geometry} material={materials.Cervical_spine} skeleton={nodes.C5_Plane055.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="C6_Plane056" geometry={nodes.C6_Plane056.geometry} material={materials.Cervical_spine} skeleton={nodes.C6_Plane056.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="c7_Plane057" geometry={nodes.c7_Plane057.geometry} material={materials.Cervical_spine} skeleton={nodes.c7_Plane057.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Calcanius_L_Plane237" geometry={nodes.Calcanius_L_Plane237.geometry} material={materials.Leg} skeleton={nodes.Calcanius_L_Plane237.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Calcanius_R_Plane146" geometry={nodes.Calcanius_R_Plane146.geometry} material={materials.Leg} skeleton={nodes.Calcanius_R_Plane146.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Capitate_L_Plane208" geometry={nodes.Capitate_L_Plane208.geometry} material={materials.Arm} skeleton={nodes.Capitate_L_Plane208.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Capitate_R_Plane122" geometry={nodes.Capitate_R_Plane122.geometry} material={materials.Arm} skeleton={nodes.Capitate_R_Plane122.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Clavicle_L_Plane200" geometry={nodes.Clavicle_L_Plane200.geometry} material={materials.Arm} skeleton={nodes.Clavicle_L_Plane200.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Clavicle_R_Plane111" geometry={nodes.Clavicle_R_Plane111.geometry} material={materials.Arm} skeleton={nodes.Clavicle_R_Plane111.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Cocix_1_Plane077" geometry={nodes.Cocix_1_Plane077.geometry} material={materials.Lumbar_spine} skeleton={nodes.Cocix_1_Plane077.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Coxix_2_Plane076" geometry={nodes.Coxix_2_Plane076.geometry} material={materials.Lumbar_spine} skeleton={nodes.Coxix_2_Plane076.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Cuboid_L_Plane242" geometry={nodes.Cuboid_L_Plane242.geometry} material={materials.Leg} skeleton={nodes.Cuboid_L_Plane242.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Cuboid_R_Plane151" geometry={nodes.Cuboid_R_Plane151.geometry} material={materials.Leg} skeleton={nodes.Cuboid_R_Plane151.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_1_L_Plane249" geometry={nodes.Distal_Phalanx_1_L_Plane249.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_1_L_Plane249.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_1_R_Plane166" geometry={nodes.Distal_Phalanx_1_R_Plane166.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_1_R_Plane166.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_2_L_Plane252" geometry={nodes.Distal_Phalanx_2_L_Plane252.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_2_L_Plane252.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_2_R_Plane191" geometry={nodes.Distal_Phalanx_2_R_Plane191.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_2_R_Plane191.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_3_L_Plane255" geometry={nodes.Distal_Phalanx_3_L_Plane255.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_3_L_Plane255.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_3_R_Plane192" geometry={nodes.Distal_Phalanx_3_R_Plane192.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_3_R_Plane192.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_4_L_Plane258" geometry={nodes.Distal_Phalanx_4_L_Plane258.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_4_L_Plane258.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_4_R_Plane193" geometry={nodes.Distal_Phalanx_4_R_Plane193.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_4_R_Plane193.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_5_L_Plane261" geometry={nodes.Distal_Phalanx_5_L_Plane261.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_5_L_Plane261.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Distal_Phalanx_5_R_Plane194" geometry={nodes.Distal_Phalanx_5_R_Plane194.geometry} material={materials.Leg} skeleton={nodes.Distal_Phalanx_5_R_Plane194.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Ethmoid_Plane005" geometry={nodes.Ethmoid_Plane005.geometry} material={materials.Skull} skeleton={nodes.Ethmoid_Plane005.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Femur_L_Plane232" geometry={nodes.Femur_L_Plane232.geometry} material={materials.Leg} skeleton={nodes.Femur_L_Plane232.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Femur_R_Plane143" geometry={nodes.Femur_R_Plane143.geometry} material={materials.Leg} skeleton={nodes.Femur_R_Plane143.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Fibula_L_Plane235" geometry={nodes.Fibula_L_Plane235.geometry} material={materials.Leg} skeleton={nodes.Fibula_L_Plane235.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Fibula_R_Plane075" geometry={nodes.Fibula_R_Plane075.geometry} material={materials.Leg} skeleton={nodes.Fibula_R_Plane075.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Frontal_Plane" geometry={nodes.Frontal_Plane.geometry} material={materials.Skull} skeleton={nodes.Frontal_Plane.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Hamate_L_Plane210" geometry={nodes.Hamate_L_Plane210.geometry} material={materials.Arm} skeleton={nodes.Hamate_L_Plane210.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Hamate_R_Plane116" geometry={nodes.Hamate_R_Plane116.geometry} material={materials.Arm} skeleton={nodes.Hamate_R_Plane116.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Humerus_L_Plane202" geometry={nodes.Humerus_L_Plane202.geometry} material={materials.Arm} skeleton={nodes.Humerus_L_Plane202.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Humerus_R_Plane113" geometry={nodes.Humerus_R_Plane113.geometry} material={materials.Arm} skeleton={nodes.Humerus_R_Plane113.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Inferior_nasal_concha_L_Plane016" geometry={nodes.Inferior_nasal_concha_L_Plane016.geometry} material={materials.Skull} skeleton={nodes.Inferior_nasal_concha_L_Plane016.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Inferior_nasal_concha_R_Plane014" geometry={nodes.Inferior_nasal_concha_R_Plane014.geometry} material={materials.Skull} skeleton={nodes.Inferior_nasal_concha_R_Plane014.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intermediate_cuneiform_L_Plane240" geometry={nodes.Intermediate_cuneiform_L_Plane240.geometry} material={materials.Leg} skeleton={nodes.Intermediate_cuneiform_L_Plane240.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intermediate_cuneiform_R_Plane150" geometry={nodes.Intermediate_cuneiform_R_Plane150.geometry} material={materials.Leg} skeleton={nodes.Intermediate_cuneiform_R_Plane150.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_10_Plane180" geometry={nodes.Intervertebral_discs_10_Plane180.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_10_Plane180.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_11_Plane179" geometry={nodes.Intervertebral_discs_11_Plane179.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_11_Plane179.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_12_Plane178" geometry={nodes.Intervertebral_discs_12_Plane178.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_12_Plane178.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_13_Plane177" geometry={nodes.Intervertebral_discs_13_Plane177.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_13_Plane177.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_14_Plane176" geometry={nodes.Intervertebral_discs_14_Plane176.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_14_Plane176.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_15_Plane175" geometry={nodes.Intervertebral_discs_15_Plane175.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_15_Plane175.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_16_Plane174" geometry={nodes.Intervertebral_discs_16_Plane174.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_16_Plane174.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_17_Plane173" geometry={nodes.Intervertebral_discs_17_Plane173.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_17_Plane173.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_18_Plane172" geometry={nodes.Intervertebral_discs_18_Plane172.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_18_Plane172.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_19_Plane171" geometry={nodes.Intervertebral_discs_19_Plane171.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_19_Plane171.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_1_Plane189" geometry={nodes.Intervertebral_discs_1_Plane189.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_1_Plane189.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_20_Plane170" geometry={nodes.Intervertebral_discs_20_Plane170.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_20_Plane170.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_21_Plane169" geometry={nodes.Intervertebral_discs_21_Plane169.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_21_Plane169.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_22_Plane168" geometry={nodes.Intervertebral_discs_22_Plane168.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_22_Plane168.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_23_Plane167" geometry={nodes.Intervertebral_discs_23_Plane167.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_23_Plane167.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_2_Plane188" geometry={nodes.Intervertebral_discs_2_Plane188.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_2_Plane188.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_3_Plane187" geometry={nodes.Intervertebral_discs_3_Plane187.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_3_Plane187.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_4_Plane186" geometry={nodes.Intervertebral_discs_4_Plane186.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_4_Plane186.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_5_Plane185" geometry={nodes.Intervertebral_discs_5_Plane185.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_5_Plane185.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_6_Plane184" geometry={nodes.Intervertebral_discs_6_Plane184.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_6_Plane184.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_7_Plane183" geometry={nodes.Intervertebral_discs_7_Plane183.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_7_Plane183.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_8_Plane182" geometry={nodes.Intervertebral_discs_8_Plane182.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_8_Plane182.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Intervertebral_discs_9_Plane181" geometry={nodes.Intervertebral_discs_9_Plane181.geometry} material={materials.Discs} skeleton={nodes.Intervertebral_discs_9_Plane181.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="L1_Plane069" geometry={nodes.L1_Plane069.geometry} material={materials.Lumbar_spine} skeleton={nodes.L1_Plane069.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="L2_Plane070" geometry={nodes.L2_Plane070.geometry} material={materials.Lumbar_spine} skeleton={nodes.L2_Plane070.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="L3_Plane071" geometry={nodes.L3_Plane071.geometry} material={materials.Lumbar_spine} skeleton={nodes.L3_Plane071.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="L4_Plane072" geometry={nodes.L4_Plane072.geometry} material={materials.Lumbar_spine} skeleton={nodes.L4_Plane072.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="L5_Plane073" geometry={nodes.L5_Plane073.geometry} material={materials.Lumbar_spine} skeleton={nodes.L5_Plane073.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lacremal_L_Plane197" geometry={nodes.Lacremal_L_Plane197.geometry} material={materials.Skull} skeleton={nodes.Lacremal_L_Plane197.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lacremal_R_Plane012" geometry={nodes.Lacremal_R_Plane012.geometry} material={materials.Skull} skeleton={nodes.Lacremal_R_Plane012.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lateral_cuneiform_L_Plane241" geometry={nodes.Lateral_cuneiform_L_Plane241.geometry} material={materials.Leg} skeleton={nodes.Lateral_cuneiform_L_Plane241.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lateral_cuneiform_R_Plane149" geometry={nodes.Lateral_cuneiform_R_Plane149.geometry} material={materials.Leg} skeleton={nodes.Lateral_cuneiform_R_Plane149.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lunate_L_Plane209" geometry={nodes.Lunate_L_Plane209.geometry} material={materials.Arm} skeleton={nodes.Lunate_L_Plane209.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Lunate_R_Plane117" geometry={nodes.Lunate_R_Plane117.geometry} material={materials.Arm} skeleton={nodes.Lunate_R_Plane117.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Mandibula_Plane024" geometry={nodes.Mandibula_Plane024.geometry} material={materials.Skull} skeleton={nodes.Mandibula_Plane024.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Maxila_L_Plane008" geometry={nodes.Maxila_L_Plane008.geometry} material={materials.Skull} skeleton={nodes.Maxila_L_Plane008.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Maxila_R_Plane010" geometry={nodes.Maxila_R_Plane010.geometry} material={materials.Skull} skeleton={nodes.Maxila_R_Plane010.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Medial_Cuneiform_L_Plane239" geometry={nodes.Medial_Cuneiform_L_Plane239.geometry} material={materials.Leg} skeleton={nodes.Medial_Cuneiform_L_Plane239.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Medial_Cuneiform_R_Plane148" geometry={nodes.Medial_Cuneiform_R_Plane148.geometry} material={materials.Leg} skeleton={nodes.Medial_Cuneiform_R_Plane148.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Menubrium_LP_Plane105" geometry={nodes.Menubrium_LP_Plane105.geometry} material={materials.Ribcage} skeleton={nodes.Menubrium_LP_Plane105.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal1_L_Plane213" geometry={nodes.Metacarpal1_L_Plane213.geometry} material={materials.Arm} skeleton={nodes.Metacarpal1_L_Plane213.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal1_R_Plane123" geometry={nodes.Metacarpal1_R_Plane123.geometry} material={materials.Arm} skeleton={nodes.Metacarpal1_R_Plane123.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal2_L_Plane216" geometry={nodes.Metacarpal2_L_Plane216.geometry} material={materials.Arm} skeleton={nodes.Metacarpal2_L_Plane216.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal2_R_Plane124" geometry={nodes.Metacarpal2_R_Plane124.geometry} material={materials.Arm} skeleton={nodes.Metacarpal2_R_Plane124.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal3_L_Plane217" geometry={nodes.Metacarpal3_L_Plane217.geometry} material={materials.Arm} skeleton={nodes.Metacarpal3_L_Plane217.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal3_R_Plane125" geometry={nodes.Metacarpal3_R_Plane125.geometry} material={materials.Arm} skeleton={nodes.Metacarpal3_R_Plane125.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal4_L_Plane218" geometry={nodes.Metacarpal4_L_Plane218.geometry} material={materials.Arm} skeleton={nodes.Metacarpal4_L_Plane218.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal4_R_Plane126" geometry={nodes.Metacarpal4_R_Plane126.geometry} material={materials.Arm} skeleton={nodes.Metacarpal4_R_Plane126.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal5_L_Plane219" geometry={nodes.Metacarpal5_L_Plane219.geometry} material={materials.Arm} skeleton={nodes.Metacarpal5_L_Plane219.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metacarpal5_R_Plane127" geometry={nodes.Metacarpal5_R_Plane127.geometry} material={materials.Arm} skeleton={nodes.Metacarpal5_R_Plane127.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_1_L_Plane243" geometry={nodes.Metatarsal_1_L_Plane243.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_1_L_Plane243.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_1_R_Plane152" geometry={nodes.Metatarsal_1_R_Plane152.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_1_R_Plane152.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_2_L_Plane244" geometry={nodes.Metatarsal_2_L_Plane244.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_2_L_Plane244.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_2_R_Plane153" geometry={nodes.Metatarsal_2_R_Plane153.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_2_R_Plane153.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_3_L_Plane245" geometry={nodes.Metatarsal_3_L_Plane245.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_3_L_Plane245.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_3_R_Plane154" geometry={nodes.Metatarsal_3_R_Plane154.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_3_R_Plane154.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_4_L_Plane246" geometry={nodes.Metatarsal_4_L_Plane246.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_4_L_Plane246.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_4_R_Plane155" geometry={nodes.Metatarsal_4_R_Plane155.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_4_R_Plane155.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_5_L_Plane247" geometry={nodes.Metatarsal_5_L_Plane247.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_5_L_Plane247.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Metatarsal_5_R_Plane156" geometry={nodes.Metatarsal_5_R_Plane156.geometry} material={materials.Leg} skeleton={nodes.Metatarsal_5_R_Plane156.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_2_L_Plane251" geometry={nodes.Middle_Phalanx_2_L_Plane251.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_2_L_Plane251.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_2_R_Plane162" geometry={nodes.Middle_Phalanx_2_R_Plane162.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_2_R_Plane162.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_3_L_Plane254" geometry={nodes.Middle_Phalanx_3_L_Plane254.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_3_L_Plane254.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_3_R_Plane163" geometry={nodes.Middle_Phalanx_3_R_Plane163.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_3_R_Plane163.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_4_L_Plane257" geometry={nodes.Middle_Phalanx_4_L_Plane257.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_4_L_Plane257.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_4_R_Plane164" geometry={nodes.Middle_Phalanx_4_R_Plane164.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_4_R_Plane164.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_5_L_Plane260" geometry={nodes.Middle_Phalanx_5_L_Plane260.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_5_L_Plane260.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Middle_Phalanx_5_R_Plane165" geometry={nodes.Middle_Phalanx_5_R_Plane165.geometry} material={materials.Leg} skeleton={nodes.Middle_Phalanx_5_R_Plane165.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Nasal_L_Plane009" geometry={nodes.Nasal_L_Plane009.geometry} material={materials.Skull} skeleton={nodes.Nasal_L_Plane009.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Nasal_R_Plane011" geometry={nodes.Nasal_R_Plane011.geometry} material={materials.Skull} skeleton={nodes.Nasal_R_Plane011.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Navicluar_L_Plane238" geometry={nodes.Navicluar_L_Plane238.geometry} material={materials.Leg} skeleton={nodes.Navicluar_L_Plane238.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Navicluar_R_Plane147" geometry={nodes.Navicluar_R_Plane147.geometry} material={materials.Leg} skeleton={nodes.Navicluar_R_Plane147.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Occipital_Plane004" geometry={nodes.Occipital_Plane004.geometry} material={materials.Skull} skeleton={nodes.Occipital_Plane004.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Palatine_Plane013" geometry={nodes.Palatine_Plane013.geometry} material={materials.Skull} skeleton={nodes.Palatine_Plane013.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Parietal_L_Plane001" geometry={nodes.Parietal_L_Plane001.geometry} material={materials.Skull} skeleton={nodes.Parietal_L_Plane001.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Parietal_R_Plane002" geometry={nodes.Parietal_R_Plane002.geometry} material={materials.Skull} skeleton={nodes.Parietal_R_Plane002.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Patella_L_Plane233" geometry={nodes.Patella_L_Plane233.geometry} material={materials.Leg} skeleton={nodes.Patella_L_Plane233.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Patella_R_Plane144" geometry={nodes.Patella_R_Plane144.geometry} material={materials.Leg} skeleton={nodes.Patella_R_Plane144.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Pelvis_L_Plane199" geometry={nodes.Pelvis_L_Plane199.geometry} material={materials.Pelvis} skeleton={nodes.Pelvis_L_Plane199.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Pelvis_R_Plane110" geometry={nodes.Pelvis_R_Plane110.geometry} material={materials.Pelvis} skeleton={nodes.Pelvis_R_Plane110.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange1_distal_L_Plane215" geometry={nodes.Phalange1_distal_L_Plane215.geometry} material={materials.Arm} skeleton={nodes.Phalange1_distal_L_Plane215.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange1_distal_R_Plane141" geometry={nodes.Phalange1_distal_R_Plane141.geometry} material={materials.Arm} skeleton={nodes.Phalange1_distal_R_Plane141.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange1_middle_L_Plane214" geometry={nodes.Phalange1_middle_L_Plane214.geometry} material={materials.Arm} skeleton={nodes.Phalange1_middle_L_Plane214.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange1_middle_R_Plane140" geometry={nodes.Phalange1_middle_R_Plane140.geometry} material={materials.Arm} skeleton={nodes.Phalange1_middle_R_Plane140.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_distal_L_Plane222" geometry={nodes.Phalange2_distal_L_Plane222.geometry} material={materials.Arm} skeleton={nodes.Phalange2_distal_L_Plane222.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_distal_R_Plane139" geometry={nodes.Phalange2_distal_R_Plane139.geometry} material={materials.Arm} skeleton={nodes.Phalange2_distal_R_Plane139.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_middle_L_Plane221" geometry={nodes.Phalange2_middle_L_Plane221.geometry} material={materials.Arm} skeleton={nodes.Phalange2_middle_L_Plane221.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_middle_R_Plane138" geometry={nodes.Phalange2_middle_R_Plane138.geometry} material={materials.Arm} skeleton={nodes.Phalange2_middle_R_Plane138.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_proximal_L_Plane220" geometry={nodes.Phalange2_proximal_L_Plane220.geometry} material={materials.Arm} skeleton={nodes.Phalange2_proximal_L_Plane220.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange2_proximal_R_Plane137" geometry={nodes.Phalange2_proximal_R_Plane137.geometry} material={materials.Arm} skeleton={nodes.Phalange2_proximal_R_Plane137.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_distal_L_Plane225" geometry={nodes.Phalange3_distal_L_Plane225.geometry} material={materials.Arm} skeleton={nodes.Phalange3_distal_L_Plane225.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_distal_R_Plane136" geometry={nodes.Phalange3_distal_R_Plane136.geometry} material={materials.Arm} skeleton={nodes.Phalange3_distal_R_Plane136.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_middle_L_Plane224" geometry={nodes.Phalange3_middle_L_Plane224.geometry} material={materials.Arm} skeleton={nodes.Phalange3_middle_L_Plane224.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_middle_R_Plane135" geometry={nodes.Phalange3_middle_R_Plane135.geometry} material={materials.Arm} skeleton={nodes.Phalange3_middle_R_Plane135.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_proximal_L_Plane223" geometry={nodes.Phalange3_proximal_L_Plane223.geometry} material={materials.Arm} skeleton={nodes.Phalange3_proximal_L_Plane223.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange3_proximal_R_Plane134" geometry={nodes.Phalange3_proximal_R_Plane134.geometry} material={materials.Arm} skeleton={nodes.Phalange3_proximal_R_Plane134.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_distal_L_Plane228" geometry={nodes.Phalange4_distal_L_Plane228.geometry} material={materials.Arm} skeleton={nodes.Phalange4_distal_L_Plane228.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_distal_R_Plane133" geometry={nodes.Phalange4_distal_R_Plane133.geometry} material={materials.Arm} skeleton={nodes.Phalange4_distal_R_Plane133.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_middle_L_Plane227" geometry={nodes.Phalange4_middle_L_Plane227.geometry} material={materials.Arm} skeleton={nodes.Phalange4_middle_L_Plane227.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_middle_R_Plane132" geometry={nodes.Phalange4_middle_R_Plane132.geometry} material={materials.Arm} skeleton={nodes.Phalange4_middle_R_Plane132.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_proximal_L_Plane226" geometry={nodes.Phalange4_proximal_L_Plane226.geometry} material={materials.Arm} skeleton={nodes.Phalange4_proximal_L_Plane226.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange4_proximal_R_Plane131" geometry={nodes.Phalange4_proximal_R_Plane131.geometry} material={materials.Arm} skeleton={nodes.Phalange4_proximal_R_Plane131.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_distal_L_Plane231" geometry={nodes.Phalange5_distal_L_Plane231.geometry} material={materials.Arm} skeleton={nodes.Phalange5_distal_L_Plane231.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_distal_R_Plane130" geometry={nodes.Phalange5_distal_R_Plane130.geometry} material={materials.Arm} skeleton={nodes.Phalange5_distal_R_Plane130.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_middle_L_Plane230" geometry={nodes.Phalange5_middle_L_Plane230.geometry} material={materials.Arm} skeleton={nodes.Phalange5_middle_L_Plane230.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_middle_R_Plane129" geometry={nodes.Phalange5_middle_R_Plane129.geometry} material={materials.Arm} skeleton={nodes.Phalange5_middle_R_Plane129.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_proximal_L_Plane229" geometry={nodes.Phalange5_proximal_L_Plane229.geometry} material={materials.Arm} skeleton={nodes.Phalange5_proximal_L_Plane229.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Phalange5_proximal_R_Plane128" geometry={nodes.Phalange5_proximal_R_Plane128.geometry} material={materials.Arm} skeleton={nodes.Phalange5_proximal_R_Plane128.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Pisiform_L_Plane212" geometry={nodes.Pisiform_L_Plane212.geometry} material={materials.Arm} skeleton={nodes.Pisiform_L_Plane212.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Pisiform_R_Plane142" geometry={nodes.Pisiform_R_Plane142.geometry} material={materials.Arm} skeleton={nodes.Pisiform_R_Plane142.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_1_L_Plane248" geometry={nodes.Proximal_Phalanx_1_L_Plane248.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_1_L_Plane248.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_1_R_Plane157" geometry={nodes.Proximal_Phalanx_1_R_Plane157.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_1_R_Plane157.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_2_L_Plane250" geometry={nodes.Proximal_Phalanx_2_L_Plane250.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_2_L_Plane250.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_2_R_Plane158" geometry={nodes.Proximal_Phalanx_2_R_Plane158.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_2_R_Plane158.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_3_L_Plane253" geometry={nodes.Proximal_Phalanx_3_L_Plane253.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_3_L_Plane253.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_3_R_Plane159" geometry={nodes.Proximal_Phalanx_3_R_Plane159.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_3_R_Plane159.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_4_L_Plane256" geometry={nodes.Proximal_Phalanx_4_L_Plane256.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_4_L_Plane256.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_4_R_Plane160" geometry={nodes.Proximal_Phalanx_4_R_Plane160.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_4_R_Plane160.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_5_L_Plane259" geometry={nodes.Proximal_Phalanx_5_L_Plane259.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_5_L_Plane259.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Proximal_Phalanx_5_R_Plane161" geometry={nodes.Proximal_Phalanx_5_R_Plane161.geometry} material={materials.Leg} skeleton={nodes.Proximal_Phalanx_5_R_Plane161.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Pubic_syphasis_LP_Plane195" geometry={nodes.Pubic_syphasis_LP_Plane195.geometry} material={materials.Pelvis} skeleton={nodes.Pubic_syphasis_LP_Plane195.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Radius_L_Plane204" geometry={nodes.Radius_L_Plane204.geometry} material={materials.Arm} skeleton={nodes.Radius_L_Plane204.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Radius_R_Plane114" geometry={nodes.Radius_R_Plane114.geometry} material={materials.Arm} skeleton={nodes.Radius_R_Plane114.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib11_L_Plane098" geometry={nodes.Rib11_L_Plane098.geometry} material={materials.Ribcage} skeleton={nodes.Rib11_L_Plane098.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_10_L_Plane096" geometry={nodes.Rib_10_L_Plane096.geometry} material={materials.Ribcage} skeleton={nodes.Rib_10_L_Plane096.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_10_R_Plane097" geometry={nodes.Rib_10_R_Plane097.geometry} material={materials.Ribcage} skeleton={nodes.Rib_10_R_Plane097.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_11_R_Plane100" geometry={nodes.Rib_11_R_Plane100.geometry} material={materials.Ribcage} skeleton={nodes.Rib_11_R_Plane100.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_12_L_Plane101" geometry={nodes.Rib_12_L_Plane101.geometry} material={materials.Ribcage} skeleton={nodes.Rib_12_L_Plane101.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_12_R_Plane102" geometry={nodes.Rib_12_R_Plane102.geometry} material={materials.Ribcage} skeleton={nodes.Rib_12_R_Plane102.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_1_L_Plane078" geometry={nodes.Rib_1_L_Plane078.geometry} material={materials.Ribcage} skeleton={nodes.Rib_1_L_Plane078.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_1_R_Plane079" geometry={nodes.Rib_1_R_Plane079.geometry} material={materials.Ribcage} skeleton={nodes.Rib_1_R_Plane079.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_2_L_Plane081" geometry={nodes.Rib_2_L_Plane081.geometry} material={materials.Ribcage} skeleton={nodes.Rib_2_L_Plane081.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_2_R_Plane080" geometry={nodes.Rib_2_R_Plane080.geometry} material={materials.Ribcage} skeleton={nodes.Rib_2_R_Plane080.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_3_L_Plane082" geometry={nodes.Rib_3_L_Plane082.geometry} material={materials.Ribcage} skeleton={nodes.Rib_3_L_Plane082.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_3_R_Plane083" geometry={nodes.Rib_3_R_Plane083.geometry} material={materials.Ribcage} skeleton={nodes.Rib_3_R_Plane083.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_4_L_Plane085" geometry={nodes.Rib_4_L_Plane085.geometry} material={materials.Ribcage} skeleton={nodes.Rib_4_L_Plane085.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_4_R_Plane084" geometry={nodes.Rib_4_R_Plane084.geometry} material={materials.Ribcage} skeleton={nodes.Rib_4_R_Plane084.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_5_L_Plane086" geometry={nodes.Rib_5_L_Plane086.geometry} material={materials.Ribcage} skeleton={nodes.Rib_5_L_Plane086.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_5_R_Plane087" geometry={nodes.Rib_5_R_Plane087.geometry} material={materials.Ribcage} skeleton={nodes.Rib_5_R_Plane087.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_6_L_Plane088" geometry={nodes.Rib_6_L_Plane088.geometry} material={materials.Ribcage} skeleton={nodes.Rib_6_L_Plane088.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_6_R_Plane089" geometry={nodes.Rib_6_R_Plane089.geometry} material={materials.Ribcage} skeleton={nodes.Rib_6_R_Plane089.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_7_L_Plane090" geometry={nodes.Rib_7_L_Plane090.geometry} material={materials.Ribcage} skeleton={nodes.Rib_7_L_Plane090.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_7_R_Plane091" geometry={nodes.Rib_7_R_Plane091.geometry} material={materials.Ribcage} skeleton={nodes.Rib_7_R_Plane091.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_8_L_Plane092" geometry={nodes.Rib_8_L_Plane092.geometry} material={materials.Ribcage} skeleton={nodes.Rib_8_L_Plane092.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_8_R_Plane093" geometry={nodes.Rib_8_R_Plane093.geometry} material={materials.Ribcage} skeleton={nodes.Rib_8_R_Plane093.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_9_L_Plane094" geometry={nodes.Rib_9_L_Plane094.geometry} material={materials.Ribcage} skeleton={nodes.Rib_9_L_Plane094.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_9_R_Plane095" geometry={nodes.Rib_9_R_Plane095.geometry} material={materials.Ribcage} skeleton={nodes.Rib_9_R_Plane095.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Rib_cartelage_LP_Plane106" geometry={nodes.Rib_cartelage_LP_Plane106.geometry} material={materials.Ribs_cartilage} skeleton={nodes.Rib_cartelage_LP_Plane106.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Sacrum_Plane074" geometry={nodes.Sacrum_Plane074.geometry} material={materials.Lumbar_spine} skeleton={nodes.Sacrum_Plane074.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Scaphoid_L_Plane205" geometry={nodes.Scaphoid_L_Plane205.geometry} material={materials.Arm} skeleton={nodes.Scaphoid_L_Plane205.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Scaphoid_R_Plane118" geometry={nodes.Scaphoid_R_Plane118.geometry} material={materials.Arm} skeleton={nodes.Scaphoid_R_Plane118.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Scapula_L_Plane201" geometry={nodes.Scapula_L_Plane201.geometry} material={materials.Arm} skeleton={nodes.Scapula_L_Plane201.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Scapula_R_Plane112" geometry={nodes.Scapula_R_Plane112.geometry} material={materials.Arm} skeleton={nodes.Scapula_R_Plane112.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Sphinoid_Plane007" geometry={nodes.Sphinoid_Plane007.geometry} material={materials.Skull} skeleton={nodes.Sphinoid_Plane007.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Sternum_LP_Plane108" geometry={nodes.Sternum_LP_Plane108.geometry} material={materials.Ribcage} skeleton={nodes.Sternum_LP_Plane108.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T10_Plane066" geometry={nodes.T10_Plane066.geometry} material={materials.Thoracic_spine} skeleton={nodes.T10_Plane066.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T11_Plane067" geometry={nodes.T11_Plane067.geometry} material={materials.Thoracic_spine} skeleton={nodes.T11_Plane067.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T12_Plane068" geometry={nodes.T12_Plane068.geometry} material={materials.Thoracic_spine} skeleton={nodes.T12_Plane068.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T1_Plane051" geometry={nodes.T1_Plane051.geometry} material={materials.Thoracic_spine} skeleton={nodes.T1_Plane051.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T2_Plane058" geometry={nodes.T2_Plane058.geometry} material={materials.Thoracic_spine} skeleton={nodes.T2_Plane058.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T3_Plane059" geometry={nodes.T3_Plane059.geometry} material={materials.Thoracic_spine} skeleton={nodes.T3_Plane059.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T4_Plane060" geometry={nodes.T4_Plane060.geometry} material={materials.Thoracic_spine} skeleton={nodes.T4_Plane060.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T5_Plane062" geometry={nodes.T5_Plane062.geometry} material={materials.Thoracic_spine} skeleton={nodes.T5_Plane062.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T6_Plane061" geometry={nodes.T6_Plane061.geometry} material={materials.Thoracic_spine} skeleton={nodes.T6_Plane061.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T7_Plane063" geometry={nodes.T7_Plane063.geometry} material={materials.Thoracic_spine} skeleton={nodes.T7_Plane063.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T8_Plane064" geometry={nodes.T8_Plane064.geometry} material={materials.Thoracic_spine} skeleton={nodes.T8_Plane064.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="T9_Plane065" geometry={nodes.T9_Plane065.geometry} material={materials.Thoracic_spine} skeleton={nodes.T9_Plane065.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Talus_LP001_Plane236" geometry={nodes.Talus_LP001_Plane236.geometry} material={materials.Leg} skeleton={nodes.Talus_LP001_Plane236.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Talus_R_Plane145" geometry={nodes.Talus_R_Plane145.geometry} material={materials.Leg} skeleton={nodes.Talus_R_Plane145.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Temporal_L_Plane196" geometry={nodes.Temporal_L_Plane196.geometry} material={materials.Skull} skeleton={nodes.Temporal_L_Plane196.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Temporal_R_Plane003" geometry={nodes.Temporal_R_Plane003.geometry} material={materials.Skull} skeleton={nodes.Temporal_R_Plane003.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tibia_L_Plane234" geometry={nodes.Tibia_L_Plane234.geometry} material={materials.Leg} skeleton={nodes.Tibia_L_Plane234.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tibia_R_Plane190" geometry={nodes.Tibia_R_Plane190.geometry} material={materials.Leg} skeleton={nodes.Tibia_R_Plane190.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth10_Plane027" geometry={nodes.Tooth10_Plane027.geometry} material={materials.Skull} skeleton={nodes.Tooth10_Plane027.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth11_Plane028" geometry={nodes.Tooth11_Plane028.geometry} material={materials.Skull} skeleton={nodes.Tooth11_Plane028.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth12_Plane029" geometry={nodes.Tooth12_Plane029.geometry} material={materials.Skull} skeleton={nodes.Tooth12_Plane029.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth13_Plane030" geometry={nodes.Tooth13_Plane030.geometry} material={materials.Skull} skeleton={nodes.Tooth13_Plane030.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth14_Plane031" geometry={nodes.Tooth14_Plane031.geometry} material={materials.Skull} skeleton={nodes.Tooth14_Plane031.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth15_Plane032" geometry={nodes.Tooth15_Plane032.geometry} material={materials.Skull} skeleton={nodes.Tooth15_Plane032.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth16_Plane033" geometry={nodes.Tooth16_Plane033.geometry} material={materials.Skull} skeleton={nodes.Tooth16_Plane033.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth17_Plane034" geometry={nodes.Tooth17_Plane034.geometry} material={materials.Skull} skeleton={nodes.Tooth17_Plane034.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth18_Plane035" geometry={nodes.Tooth18_Plane035.geometry} material={materials.Skull} skeleton={nodes.Tooth18_Plane035.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth19_Plane036" geometry={nodes.Tooth19_Plane036.geometry} material={materials.Skull} skeleton={nodes.Tooth19_Plane036.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth1_Plane017" geometry={nodes.Tooth1_Plane017.geometry} material={materials.Skull} skeleton={nodes.Tooth1_Plane017.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth20_Plane037" geometry={nodes.Tooth20_Plane037.geometry} material={materials.Skull} skeleton={nodes.Tooth20_Plane037.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth21_Plane038" geometry={nodes.Tooth21_Plane038.geometry} material={materials.Skull} skeleton={nodes.Tooth21_Plane038.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth22_Plane039" geometry={nodes.Tooth22_Plane039.geometry} material={materials.Skull} skeleton={nodes.Tooth22_Plane039.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth23_Plane040" geometry={nodes.Tooth23_Plane040.geometry} material={materials.Skull} skeleton={nodes.Tooth23_Plane040.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth24_Plane041" geometry={nodes.Tooth24_Plane041.geometry} material={materials.Skull} skeleton={nodes.Tooth24_Plane041.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth25_Plane042" geometry={nodes.Tooth25_Plane042.geometry} material={materials.Skull} skeleton={nodes.Tooth25_Plane042.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth26_Plane043" geometry={nodes.Tooth26_Plane043.geometry} material={materials.Skull} skeleton={nodes.Tooth26_Plane043.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth27_Plane044" geometry={nodes.Tooth27_Plane044.geometry} material={materials.Skull} skeleton={nodes.Tooth27_Plane044.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth28_Plane045" geometry={nodes.Tooth28_Plane045.geometry} material={materials.Skull} skeleton={nodes.Tooth28_Plane045.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth29_Plane046" geometry={nodes.Tooth29_Plane046.geometry} material={materials.Skull} skeleton={nodes.Tooth29_Plane046.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth2_Plane019" geometry={nodes.Tooth2_Plane019.geometry} material={materials.Skull} skeleton={nodes.Tooth2_Plane019.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth30_Plane047" geometry={nodes.Tooth30_Plane047.geometry} material={materials.Skull} skeleton={nodes.Tooth30_Plane047.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth31_Plane048" geometry={nodes.Tooth31_Plane048.geometry} material={materials.Skull} skeleton={nodes.Tooth31_Plane048.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth32_Plane049" geometry={nodes.Tooth32_Plane049.geometry} material={materials.Skull} skeleton={nodes.Tooth32_Plane049.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth3_Plane020" geometry={nodes.Tooth3_Plane020.geometry} material={materials.Skull} skeleton={nodes.Tooth3_Plane020.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth4_Plane021" geometry={nodes.Tooth4_Plane021.geometry} material={materials.Skull} skeleton={nodes.Tooth4_Plane021.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth5_Plane022" geometry={nodes.Tooth5_Plane022.geometry} material={materials.Skull} skeleton={nodes.Tooth5_Plane022.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth6_Plane018" geometry={nodes.Tooth6_Plane018.geometry} material={materials.Skull} skeleton={nodes.Tooth6_Plane018.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth7_Plane023" geometry={nodes.Tooth7_Plane023.geometry} material={materials.Skull} skeleton={nodes.Tooth7_Plane023.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth8_Plane025" geometry={nodes.Tooth8_Plane025.geometry} material={materials.Skull} skeleton={nodes.Tooth8_Plane025.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Tooth9_Plane026" geometry={nodes.Tooth9_Plane026.geometry} material={materials.Skull} skeleton={nodes.Tooth9_Plane026.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Trapezium_L_Plane206" geometry={nodes.Trapezium_L_Plane206.geometry} material={materials.Arm} skeleton={nodes.Trapezium_L_Plane206.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Trapezium_R_Plane119" geometry={nodes.Trapezium_R_Plane119.geometry} material={materials.Arm} skeleton={nodes.Trapezium_R_Plane119.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Trapezoid_L_Plane207" geometry={nodes.Trapezoid_L_Plane207.geometry} material={materials.Arm} skeleton={nodes.Trapezoid_L_Plane207.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Trapezoid_R_Plane120" geometry={nodes.Trapezoid_R_Plane120.geometry} material={materials.Arm} skeleton={nodes.Trapezoid_R_Plane120.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Triquetrium_L_Plane211" geometry={nodes.Triquetrium_L_Plane211.geometry} material={materials.Arm} skeleton={nodes.Triquetrium_L_Plane211.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Triquetrium_R_Plane121" geometry={nodes.Triquetrium_R_Plane121.geometry} material={materials.Arm} skeleton={nodes.Triquetrium_R_Plane121.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Ulna_L_Plane203" geometry={nodes.Ulna_L_Plane203.geometry} material={materials.Arm} skeleton={nodes.Ulna_L_Plane203.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Ulna_R_Plane115" geometry={nodes.Ulna_R_Plane115.geometry} material={materials.Arm} skeleton={nodes.Ulna_R_Plane115.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Vomer_Plane015" geometry={nodes.Vomer_Plane015.geometry} material={materials.Skull} skeleton={nodes.Vomer_Plane015.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Xiphoid_process_LP_Plane109" geometry={nodes.Xiphoid_process_LP_Plane109.geometry} material={materials.Ribcage} skeleton={nodes.Xiphoid_process_LP_Plane109.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Zygomatic_L_Plane198" geometry={nodes.Zygomatic_L_Plane198.geometry} material={materials.Skull} skeleton={nodes.Zygomatic_L_Plane198.skeleton} />
          <skinnedMesh castShadow receiveShadow frustumCulled={false} name="Zygomatic_R_Plane006" geometry={nodes.Zygomatic_R_Plane006.geometry} material={materials.Skull} skeleton={nodes.Zygomatic_R_Plane006.skeleton} />
        </group>
      </group>
    </group>
  )
});

useGLTF.preload('/DecimatedSkeletonWithAnimationsAndTextures.gltf')

export default Model;
