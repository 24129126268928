import * as THREE from "three"

const to2Decimals = (val) => {
  return val == null ? null : Math.round(val * 100) / 100;
};
// Implementation is computationally expensive
const normalizeAngleToMinusPiPi = (radians => {
  if (isNaN(radians)) return 0;
  return Math.atan2(Math.sin(radians), Math.cos(radians));
})
const radiansDebugString = (radians => {
  if (isNaN(radians)) return"";
  return `${radians.toFixed(2)} (${THREE.MathUtils.radToDeg(radians).toFixed(2)}\u00B0)`
});
const vec3DebugString = (vec3 => {
  return `(${vec3?.x?.toFixed(2)}, ${vec3?.y?.toFixed(2)}, ${vec3?.z?.toFixed(2)})`
});
const eulerDebugString = (euler => {
  return `(${Util.radiansDebugString(euler.x)}, ${Util.radiansDebugString(euler.y)}, ${Util.radiansDebugString(euler.z)})`
});
const isTouchDevice = () => {
  // See https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
  return (('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  (navigator.msMaxTouchPoints > 0));
};
const length = ([fromX, fromY, fromZ], [toX, toY, toZ]) =>
{
  let diffX = toX - fromX;
  let diffY = toY - fromY;
  let diffZ = toZ - fromZ;
  let result = Math.sqrt(diffX*diffX + diffY*diffY + diffZ*diffZ);
  return result;
}


let Util = {
  to2Decimals,
  normalizeAngleToMinusPiPi,
  radiansDebugString,
  vec3DebugString,
  eulerDebugString,
  isTouchDevice,
  length,
}
export default Util;