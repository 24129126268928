import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import * as THREE from "three"
import { useFrame } from '@react-three/fiber';
import { easing } from 'maath'
import { Text3D, useHelper, meshBounds } from "@react-three/drei";
import { BoxHelper } from "three";


const GameMarkerText3D = React.forwardRef((props, ref) => {
  const {
    text="text",
    position=[0,0,0],
    rotation=[0,  -Math.PI/2, 0],
    height=.5,
    onClick=function(e){},
    isActive=false,
  } = props;
  const initialYRotation = -Math.PI/2
  const inactiveOpacity = 0.7;
  const [isActiveState, setIsActiveState] = useState(isActive);
  const [isRotating, setIsRotating] = useState(false);
  const [isRising, setIsRising] = useState(false);
  const [isLowering, setIsLowering] = useState(false);
  const textRef = useRef();

  const reusableVec3 =  new THREE.Vector3(); // here so not reallocating new vec3 in useFrame, performance optimisation.

  function localOnClick(e) {
    e.stopPropagation();
    setIsRotating(true);
    setIsActiveState(isActive => !isActive);
  }

  useImperativeHandle(ref, () => ({
    getIsActive: () => {
      return isActiveState;
    },
    setIsActive: (newIsActive) => {
      setIsActiveState(newIsActive);
    },
    getText: () => {
      return text;
    },
    getCurrentRef: () => {
      return textRef.current;
    }
  }
  ));

  // useHelper(textRef, BoxHelper, 'red');

  useEffect(() => {
    textRef.current.geometry.computeBoundingBox();
    textRef.current.geometry.translate(-(textRef.current.geometry.boundingBox.max.x - textRef.current.geometry.boundingBox.min.x)/2, 0, 0); // recenter so rotations behave as you'd expect
  }, []);

  useEffect(() => {
    if (isActiveState) {
      setIsRising(true);
      setIsLowering(false);
      textRef.current.material.opacity = 1;
    } else {
      setIsRising(false);
      setIsLowering(true);
      textRef.current.material.opacity = inactiveOpacity;
    }
  }, [isActiveState])

  useFrame((state, delta) => { // rotation
    if (!isRotating) return;
    if (textRef.current.rotation.y <= initialYRotation - Math.PI*2) {
      textRef.current.rotation.y = initialYRotation;
      setIsRotating(false);
    }
    easing.damp3(textRef.current.rotation, [0, initialYRotation - Math.PI*2 - 0.2, 0], 0.25, delta);
    // console.log(textRef.current.rotation.y);
  });

  useFrame(state => { // rise/lower
    const lerpAlpha = 0.075;
    if (!isRising && !isLowering) return;
    if (isRising) {
      if (textRef.current.position.z >= 0.5 - 0.00001) {
        setIsRising(false);
        textRef.current.position.setComponent(1, 0.5);
      } else {
        let newPos =  reusableVec3.set(textRef.current.position.x, 0.5, textRef.current.position.z);
        textRef.current.position.lerp(newPos, lerpAlpha);
      }
    }
    else if (isLowering) {
      if (textRef.current.position.z <= 0) {
        setIsLowering(false);
        textRef.current.position.setComponent(1, 0);
      } else {
        let newPos =  reusableVec3.set(textRef.current.position.x, 0, textRef.current.position.z);
        textRef.current.position.lerp(newPos, lerpAlpha);
      }
    }
  });

  return(
    <Text3D 
      ref={textRef}
      raycast={meshBounds}
      castShadow
      receiveShadow
      transparent
      position={position}
      rotation={rotation}
      curveSegments={32}
      bevelEnabled
      bevelSize={0.02}
      bevelThickness={0.05}
      height={0.02}
      lineHeight={1}
      letterSpacing={-0.06}
      size={height}
      font="/fonts/Inter_Bold.json"
      onClick={(e) => {e.stopPropagation(); localOnClick(e); if (onClick != null) {onClick();}}}
      // onPointerUp={e => {e.stopPropagation();}}
      visible={props.visible}
    >
      {text}
      <meshNormalMaterial transparent opacity={isActive ? 1 : inactiveOpacity}/>
    </Text3D>
  );
});

export default GameMarkerText3D