import React, {useState} from "react";
import { Html, useProgress } from "@react-three/drei";

function LoadingMessage(...props) {
  const MILLIS_PER_SECOND = 1000;
  const [startTimeInMillis] = useState(new Date().getTime());

  // eslint-disable-next-line no-unused-vars
  const { active, progress, errors, item, loaded, total } = useProgress();
  var currentMillis = new Date().getTime();
  var message =
    startTimeInMillis != null && (currentMillis - startTimeInMillis) > MILLIS_PER_SECOND*2
    ? "Loading:" + Math.trunc(progress) + "%"
    : "";

  return (
    <group position={props.position}>
      <Html center>{message}</Html>
    </group>
  );
}

export default LoadingMessage;