import React, { Suspense, useRef, useState, useImperativeHandle } from "react";
import useLocalStorage from "use-local-storage";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import * as THREE from "three"
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Environment } from "@react-three/drei";
import AxesHelper from "./component/AxesHelper";
import GridHelper from "./component/GridHelper";
import StatsPanel from "./component/StatsPanel";
import ShowMouseCanvasCoords from "./component/ShowMouseCanvasCoords";
import FullScreenButton from "./component/FullScreenButton";
import ActionMenuButton from "./component/ActionMenuButton";
import MovieEditorButton from "./component/MovieEditorButton";
import GameMenuButton from "./component/GameMenuButton";
import DebugMenuButton from "./component/DebugMenuButton";
import KeyLight from "./component/KeyLight";
import ShadowGroundPlane from "./component/ShadowGroundPlane";
import LoadingMessage from "./component/LoadingMessage";
// import MaleSkeleton from "./component/MaleSkeleton";
// import TexturedWavingSkeleton from "./component/TexturedWavingSkeleton";
// import Decimated50TexturedWavingSkeleton from "./component/Decimated50TexturedWavingSkeleton";
import DecimatedSkeletonWithAnimationsAndTextures from "./component/DecimatedSkeletonWithAnimationsAndTextures";
import GameMarkers from "./component/GameMarkers";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import GetMouseCanvasCoords from "./component/GetMouseCanvasCoords";

function App() {
  const SHADOW_GROUND_PLANE_NAME = "ShadowGroundPlane";
  const fullScreenHandle = useFullScreenHandle();
  const environmentHdrFile = "./hdri/goegap_2k.hdr";
  const environmentRadius = 2000.0;
  const environmentScale = 50.0;
  const gameMarkers = [
    {position: [2,    0, -1.5], label: "1"},
    {position: [3,    0, 1.5],  label: "2"},
    {position: [-1,   0, 1.5],  label: "3"},
    {position: [-1,   0, -1.5], label: "4"},
    {position: [-2.5, 0, -2],   label: "5"},
    {position: [-4,   0, 3],    label: "6"},
  ];

  const intersectsComponentRef = useRef(null);
  const cameraPositionResetComponentRef = useRef(null);
  const orbitControlsResetComponentRef = useRef(null);
  const skeletonRef = useRef(null);
  const gameMarkersRef = useRef(null);
  const movieEditorButtonRef = useRef(null);
  const showMouseCanvasCoordsRef = useRef(null);
  const getMouseCanvasCoordsRef = useRef(null);

  const labelToActionName = new Map();
  labelToActionName.set("Idle", "Idle");
  labelToActionName.set("Wave", "Waving");
  labelToActionName.set("Jump", "Jump");
  labelToActionName.set("Look Behind Left", "Looking Behind Left");
  labelToActionName.set("Look Behind Right", "Looking Behind Right");
  labelToActionName.set("Look Far", "Looking");
  labelToActionName.set("YMCA", "YMCA Dance");
  labelToActionName.set("Twist", "Twist Dance");
  labelToActionName.set("Chicken Dance", "Chicken Dance");
  labelToActionName.set("Can-Can", "Jazz Dancing");
  labelToActionName.set("Idle to Push Up", "Idle to Push Up");
  labelToActionName.set("Push Up", "Push Up");
  labelToActionName.set("Push Up to Idle", "Push Up to Idle");

  const initialCameraPosition = [-3, 0.5, 0];
  const initialCameraTarget = [0, 1, 0];
  const [isDebug, setIsDebug] = useState(window.location.hostname.toLowerCase().startsWith("dev") || window.location.hostname.toLowerCase().startsWith("localhost"));
  const [showStats, setShowStats] =  useLocalStorage("showStats", isDebug);
  const [showAxesHelper, setShowAxesHelper] =  useLocalStorage("showAxesHelper", isDebug);
  const [showGridHelper, setShowGridHelper] =  useLocalStorage("showGridHelper", isDebug);
  const [showMouseCoords, setShowMouseCoords] =  useLocalStorage("showmouseCoords", isDebug);
  const [showGameMarkers, setShowGameMarkers] = useLocalStorage("showGameMarkers", true);
  const [skeletonHasRenderedFirstTime, setSkeletonHasRenderedFirstTime] = useState(false);

  const markerOnClick = (lookAt, markerBoundingBox) => {
    skeletonRef.current?.doWalkToPosition(lookAt, markerBoundingBox);
  }
  
  const resetCameraPosition = () => {
    cameraPositionResetComponentRef.current.doResetCameraPosition();
    orbitControlsResetComponentRef.current.doResetOrbitControls();

  }
  
  const doSkeletonWaveIfIdle = () => {
    skeletonRef.current.doAction("Waving", true); // only wave if no other amimation is currently playing
  };

  const CameraPositionResetComponent = React.forwardRef((props, ref) => {
    const [resetCameraPosition, setResetCameraPosition] = useState(false);
    
    useFrame((state) => {
      if (!resetCameraPosition) return;
      if ((initialCameraPosition[0]-state.camera.position.x)*(initialCameraPosition[0]-state.camera.position.x) +
          (initialCameraPosition[1]-state.camera.position.y)*(initialCameraPosition[1]-state.camera.position.y) +
          (initialCameraPosition[2]-state.camera.position.z)*(initialCameraPosition[2]-state.camera.position.z)
          <= 0.000001) {
        state.camera.position.set(initialCameraPosition[0], initialCameraPosition[1], initialCameraPosition[2]);
        setResetCameraPosition(false);
      }
      const newCameraPos =  new THREE.Vector3(initialCameraPosition[0], initialCameraPosition[1], initialCameraPosition[2]);
      state.camera.position.lerp(newCameraPos, 0.1);
    });

    useImperativeHandle(ref, () => ({
      doResetCameraPosition: () => {
        setResetCameraPosition(true);
      }
    }));
  });

  const OrbitControlsResetComponent = React.forwardRef((props, ref) => {
    const [resetOrbitControls, setResetOrbitControls] = useState(false);

    useFrame(state => {
      if (!resetOrbitControls) return;
      setResetOrbitControls(false);
      // console.log(state.controls);
      state.controls.target.set(initialCameraTarget[0], initialCameraTarget[1], initialCameraTarget[2])
    });

    useImperativeHandle(ref, () => ({
      doResetOrbitControls: () => {
        setResetOrbitControls(true);
      }
    }));
  })

  function ClampCameraYAboveZeroComponent() {
    useFrame((state, delta, xrFrame) => {
      const minY = 0.001;
      var cameraPosition = state.camera.position;
      if (cameraPosition.y < minY) {
        state.camera.position.set(cameraPosition.x, minY, cameraPosition.z);
      }
    });
  }

  const IntersectsComponent = React.forwardRef((props, ref) => {
    const { raycaster, scene } = useThree();

    useImperativeHandle(ref, () => ({
      getIntersects: (e) => {
        return raycaster.intersectObjects(scene.children);
      }
    }));

  });

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <div id="debug-panels-area" className="opacity-75 no-print">
          { isDebug ? <StatsPanel showPanel={showStats ? 0 : null} parentDivId="debug-panels-area" /> : null }
          { isDebug && showMouseCoords ? <ShowMouseCanvasCoords ref={showMouseCanvasCoordsRef}  /> : null }
        </div>
        <div id="buttons-area" className="opacity-75 no-print">
          {
            isDebug ?
              <div className="buttons-area-column">
                <DebugMenuButton
                  showPerformanceGraph={showStats}
                  handleShowPerformanceGraph={show => setShowStats(show)}
                  showAxesHelper={showAxesHelper}
                  handleShowAxesHelper={show => setShowAxesHelper(show)}
                  showGridHelper={showGridHelper}
                  handleShowGridHelper={show => setShowGridHelper(show)}
                  showMouseCoords={showMouseCoords}
                  handleShowMouseCoords={show => setShowMouseCoords(show)}
                />
              </div>
            : null
          }
          <div className="buttons-area-column">
            <GameMenuButton
              showGameMarkers={showGameMarkers}
              handleResetSkeletonPosition={() => skeletonRef.current.doResetModelPositionAndRotation()}
              handleShowGameMarkers={show => setShowGameMarkers(show)}
          />
          </div>
          <div className="buttons-area-column">
            <FullScreenButton fullScreenHandle={fullScreenHandle} />
            <ActionMenuButton
              disabled={!skeletonHasRenderedFirstTime}
              style={{paddingTop: "5px"}}
              size={null}
              resetCameraPositionFunction={resetCameraPosition}
              options={Array.from(labelToActionName.keys())}
              onToggle={show => {if (show) movieEditorButtonRef.current.hideDropDown()}}
              handleSelect={label => {if (label != null) skeletonRef.current.doAction(labelToActionName.get(label))}}
            />
            <MovieEditorButton
              ref={movieEditorButtonRef}
              disabled={!skeletonHasRenderedFirstTime}
              style={{paddingTop: "5px"}}
              options={Array.from(labelToActionName.keys())}
              onPlay={(actionsWithActionLabels) => {
                let actionsWithActionNames = actionsWithActionLabels.map((item) => {
                  return {
                    actionName: labelToActionName.get(item.actionLabel),
                    repeatCount: item.repeatCount
                  }
                });
                skeletonRef.current.doActions(actionsWithActionNames);
              }}
              onLoopChange={newLoopStatus => skeletonRef.current.doLoopChange(newLoopStatus)}
              onPauseChange={newPauseStatus => skeletonRef.current.doPauseChange(newPauseStatus)}
              onStop={() => skeletonRef.current.doStop()}
            />
          </div>
        </div>
        <Suspense fallback={<div className="center-screen rotating-and-changing-letter-spacing">Loading...</div>}>
          <Canvas
            onPointerUp={(e) => {
              e.preventDefault();
              let gameMarkerIntersects = intersectsComponentRef?.current?.getIntersects().filter(hit => {
                return hit?.object?.geometry?.type === "TextGeometry";
              });
              if (gameMarkerIntersects?.length === 0) {
                doSkeletonWaveIfIdle()}
              }
            }
            onPointerDown={e => {
              let canvasCoords = getMouseCanvasCoordsRef.current?.getMouseDownCanvasCoords(e);
              showMouseCanvasCoordsRef.current?.showMouseDownCanvasCoords(canvasCoords);
            }}
            shadows
            camera={{
              position: initialCameraPosition,
              fov: 50
            }}
          >
            { isDebug && showGridHelper ? <GridHelper /> : null }
            { isDebug && showAxesHelper ? <AxesHelper /> : null }
            { isDebug && showMouseCoords ? <GetMouseCanvasCoords ref={getMouseCanvasCoordsRef} groundPlaneName={SHADOW_GROUND_PLANE_NAME} /> : null }
            <IntersectsComponent ref={intersectsComponentRef} />
            <CameraPositionResetComponent ref={cameraPositionResetComponentRef} />
            <OrbitControlsResetComponent ref={orbitControlsResetComponentRef} />
            <ClampCameraYAboveZeroComponent />
            <OrbitControls 
              makeDefault
              minPolarAngle={0.0}
              maxPolarAngle={Math.PI/2.0 + .5}
              target={initialCameraTarget}
              maxDistance={0.75*environmentRadius/environmentScale}
            />
            <KeyLight />
            <Environment
              ground={{ height: 5, radius: environmentRadius, scale: environmentScale }}
              files={environmentHdrFile}
            />
            <Suspense fallback={<LoadingMessage position={[-1,1,0]} />}>
              <DecimatedSkeletonWithAnimationsAndTextures
                ref={skeletonRef}
                handleHasRenderedFirstTime={() => setSkeletonHasRenderedFirstTime(true)
                }
                onAnimationRunStateChange={newAnimationRunState => movieEditorButtonRef.current.setAnimationRunState(newAnimationRunState)}
                position={[0,0,0]}
                rotation={[0,-Math.PI/2,0]}
              />
              <GameMarkers gameMarkers={gameMarkers} markerOnClick={marker => markerOnClick(marker.position, gameMarkersRef.current?.getMarkerBoundingBox(marker.label))} visible={showGameMarkers} ref={gameMarkersRef} />
              <ShadowGroundPlane name={SHADOW_GROUND_PLANE_NAME} />
            </Suspense>
          </Canvas>
        </Suspense>
      </FullScreen>
    </>
  );
}

export default App;
