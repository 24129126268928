import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import maxSize from "popper-max-size-modifier";


import "./ActionMenuButton.css"

function ActionMenuButton({options=["Wave"],
                            resetCameraPositionFunction = null,
                            handleSelect=function(e){console.log(e);},
                            disabled=false,
                            autoClose="outside",
                            size="lg",
                            onToggle=(show) => {/* console.log("ActionMenuButton dropdown show state is: " + show) */},
                            ...props}) {
  const actionMenuDropdownClassName = "action-menu-dropdown";
  const dropdownItems = options.map((option, index) => {
    return (
      <Dropdown.Item eventKey={option} key={index}>{option}</Dropdown.Item>
    );
  })
  const applyMaxSize = {
    name: "applyMaxSize",
    enabled: true,
    phase: "beforeWrite",
    requires: ["maxSize"],
    fn({ state }) {
      const { height } = state.modifiersData.maxSize;
      state.styles.popper.maxHeight = `${height}px`;
    }
  };

  return(
    <Dropdown 
      style={props.style}
      autoClose={autoClose}
      onToggle={show => {
        onToggle(show);
      }}
      onSelect={handleSelect}
    >
      <Dropdown.Toggle
        size={size}
        disabled={disabled}
        variant="secondary"
      />
      <Dropdown.Menu
        className={actionMenuDropdownClassName}
        variant="dark"
        style={{
          overflowY: "auto"
        }}
        popperConfig={{
          modifiers: [maxSize, applyMaxSize]
        }}
      >
        {
          resetCameraPositionFunction == null ?
          null :
          <>
            <Dropdown.Item eventKey={null} key={-1} onClick={() => {resetCameraPositionFunction(); document.body.click();}} style={{color: "green", fontWeight: "bold"}}>Reset Viewpoint</Dropdown.Item>
            <Dropdown.Divider/>
          </>
        }
        {dropdownItems}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ActionMenuButton