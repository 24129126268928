import React, { useEffect, useRef, useImperativeHandle } from "react";
import * as THREE from "three"
import GameMarkerText3D from "./GameMarkerText3D";

const GameMarkers = React.forwardRef((props, ref) => {
  const {
    gameMarkers = [], //{label: string, position: [number x, number y, number z]}
    visible = false,
    markerOnClick=function(marker){console.log(marker)}
  } = props;
// function GameMarkers({gameMarkers = [], //{label: string, position: [number x, number y, number z]}
//                       visible = false,
//                       markerOnClick=function(marker){console.log(marker)}
//                     }) {
  const markerRefs = useRef([]);

  useImperativeHandle(ref, () => ({
    getMarkerBoundingBox: (label) => { // returns null if no marker with that label, THREE.Box3 if there is one.
      let filtered = markerRefs.current?.filter(m => m.getText() === label);
      if (filtered.length === 0 ) return null;
      let markerRef = filtered[0].getCurrentRef();
      let result = new THREE.Box3().setFromObject(markerRef);
      return result;
    },
  }));

  function localMarkerOnClick(clickedMarker) {
    markerRefs.current.forEach(currentMarkerRef => {
      // console.log("currentMarkerRef.markerIsActive = currentMarkerRef.text(" + currentMarkerRef.text + ") === clickedMarker.label(" + clickedMarker.label + ")")
      currentMarkerRef.setIsActive((currentMarkerRef.getText() === clickedMarker.label) && !currentMarkerRef.getIsActive());
    });
  }

  useEffect(() => {
    markerRefs.current = markerRefs.current.slice(0, gameMarkers.length);
  }, [gameMarkers])

  return(
    visible ?
      gameMarkers.map((marker, index) =>
        <GameMarkerText3D
          ref={el => markerRefs.current[index] = el}
          position={marker.position}
          key={index}
          markerIsActive={marker.markerIsActive}
          visible={visible}
          onClick={() => {localMarkerOnClick(marker); markerOnClick(marker);}}
          text={marker.label}
        />
      )
    : null
  )
});

export default GameMarkers