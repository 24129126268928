import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import maxSize from "popper-max-size-modifier";
import Util from "../lib/Util";

import { Bug } from "react-bootstrap-icons";

import "./DebugMenuButton.css"

function DebugMenuButton({showPerformanceGraph=true,
                          handleShowPerformanceGraph=function(e){console.log(e);},
                          showMouseCoords=true,
                          handleShowMouseCoords=function(e){console.log(e);},
                          showAxesHelper=true,
                          handleShowAxesHelper=function(e){console.log(e);},
                          showGridHelper=true,
                          handleShowGridHelper=function(e){console.log(e);},
                          disabled=false,
                          autoClose=true,
                          size=null,
                          // onToggle=(show) => {/* console.log("ActionMenuButton dropdown show state is: " + show) */},
                          ...props}) {

  const [showPerfGraph, setShowPerfGraph] = useState(showPerformanceGraph);
  const [showMouseCoordsState, setShowMouseCoordsState] = useState(showMouseCoords);
  const [showAxesHelperState, setShowAxesHelperState] = useState(showAxesHelper);
  const [showGridHelperState, setShowGridHelperState] = useState(showGridHelper);
  
  const applyMaxSize = {
    name: "applyMaxSize",
    enabled: true,
    phase: "beforeWrite",
    requires: ["maxSize"],
    fn({ state }) {
      const { height } = state.modifiersData.maxSize;
      state.styles.popper.maxHeight = `${height}px`;
    }
  };

  return(
    <Dropdown 
      style={props.style}
      autoClose={autoClose}
    >
      <Dropdown.Toggle
        size={size}
        disabled={disabled}
        id="debug-menu-button"
        className="no-print"
        variant="secondary"
      >
        <Bug fontSize="1.5rem" style={{ fill: "white"}} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        variant="dark"
        style={{
          overflowY: "auto",
          zIndex: 2000
        }}
        popperConfig={{
          modifiers: [maxSize, applyMaxSize]
        }}
      >
        <Dropdown.Item eventKey={null} key="b300920e-a3be-4c5c-9885-1e264e7836c7" onClick={() => {setShowPerfGraph(showMarkers => !showMarkers); handleShowPerformanceGraph(!showPerfGraph);}}>{showPerfGraph ? "Hide " : "Show "} Performance Graph</Dropdown.Item>
        <Dropdown.Item eventKey={null} key="a1418155-bc33-4ad9-a7fb-c0f10e42fd58" onClick={() => {setShowMouseCoordsState(showMouseCoordsState => !showMouseCoordsState); handleShowMouseCoords(!showMouseCoordsState);}}>{showMouseCoordsState ? "Hide " : "Show "} Ground {Util.isTouchDevice() ? "Touch " : "Click "} Position</Dropdown.Item>
        <Dropdown.Item eventKey={null} key="a92a6ce6-8f38-4367-8fc9-6ad7cb8d43d6" onClick={() => {setShowAxesHelperState(showAxesHelperState => !showAxesHelperState); handleShowAxesHelper(!showAxesHelperState);}}>{showAxesHelperState ? "Hide " : "Show "} Axes</Dropdown.Item>
        <Dropdown.Item eventKey={null} key="74bed02a-e687-4457-a02b-e85f2b4cf04f" onClick={() => {setShowGridHelperState(showGridHelperState => !showGridHelperState); handleShowGridHelper(!showGridHelperState);}}>{showGridHelperState ? "Hide " : "Show "} Grid</Dropdown.Item>
        <Dropdown.Item eventKey={null} key="00b70150-4e8b-47af-aa87-2ee011788d43" onClick={() => {window.localStorage.clear();}}>Clear Local Storage</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DebugMenuButton