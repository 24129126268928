import React, { useRef, useImperativeHandle } from "react";
import Util from "../lib/Util";



const ShowMouseCanvasCoords = React.forwardRef((props, ref) => {
  // const {
  //   parentDivId=null
  // } = props;
  const space = "\u00A0";
  const selfRef = useRef();
  const xDivRef = useRef();
  const yDivRef = useRef();
  const zDivRef = useRef();

  useImperativeHandle(ref, () => ({
    showMouseDownCanvasCoords: (e) => {
      // console.log("showMouseDownCanvasCoords");
      // console.log(e);
      let xRounded = e.x == null? null :  Util.to2Decimals(e.x);
      let yRounded = e.y == null? null :  Util.to2Decimals(e.y);
      let zRounded = e.z == null? null :  Util.to2Decimals(e.z);
      xDivRef.current.textContent= "x:" + (xRounded >= 0 ? space : "" ) + xRounded;
      yDivRef.current.textContent= "y:" + (yRounded >= 0 ? space : "" ) + yRounded;
      zDivRef.current.textContent= "z:" + (zRounded >= 0 ? space : "" ) + zRounded;
    },
  }));

  return(
    <div ref={selfRef} style={{
      width: "8rem", 
      padding: "2px",
      backgroundColor: "white",
      display: "flex",
      flexFlow: "column"
    }}>
      <div style={{
        fontSize: "0.5rem",
      }}>Ground {Util.isTouchDevice() ? "Touch " : "Click "} Position</div>
      <div ref={xDivRef} style={{
        fontFamily: "monospace",
        lineHeight: "0.8rem",
      }}>&nbsp;</div>
      <div ref={yDivRef} style={{
        fontFamily: "monospace",
        lineHeight: "0.8rem",
      }}>&nbsp;</div>
      <div ref={zDivRef} style={{
        fontFamily: "monospace",
        lineHeight: "0.8rem",
        marginBottom: "2px",
      }}>&nbsp;</div>
    </div>
  )
});

export default ShowMouseCanvasCoords;