import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import maxSize from "popper-max-size-modifier";

import { Puzzle } from "react-bootstrap-icons";

import "./GameMenuButton.css"

function GameMenuButton({showGameMarkers=true,
                          handleResetSkeletonPosition=function(){console.log("Reset skeleton position.")},
                          handleShowGameMarkers=function(e){console.log(e);},
                          disabled=false,
                          autoClose=true,
                          size=null,
                          // onToggle=(show) => {/* console.log("ActionMenuButton dropdown show state is: " + show) */},
                          ...props}) {

  const [showMarkers, setShowMarkers] = useState(showGameMarkers);
  
  const applyMaxSize = {
    name: "applyMaxSize",
    enabled: true,
    phase: "beforeWrite",
    requires: ["maxSize"],
    fn({ state }) {
      const { height } = state.modifiersData.maxSize;
      state.styles.popper.maxHeight = `${height}px`;
    }
  };

  return(
    <Dropdown 
      style={props.style}
      autoClose={autoClose}
    >
      <Dropdown.Toggle
        size={size}
        disabled={disabled}
        id="game-menu-button"
        className="no-print"
        variant="secondary"
      >
        <Puzzle fontSize="1.5rem" style={{ fill: "white"}} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        variant="dark"
        style={{
          overflowY: "auto",
          zIndex: 2000
        }}
        popperConfig={{
          modifiers: [maxSize, applyMaxSize]
        }}
      >
        <Dropdown.Item eventKey={null} key="09c26f7b-fe00-45b8-9aa7-7555639bc453" onClick={() => {if (handleResetSkeletonPosition != null) handleResetSkeletonPosition();}}>Reset Skeleton Position</Dropdown.Item>
        <Dropdown.Item eventKey={null} key={-1} onClick={() => {setShowMarkers(showMarkers => !showMarkers); handleShowGameMarkers(!showMarkers);}}>{showMarkers ? "Hide " : "Show "} Game Markers</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default GameMenuButton