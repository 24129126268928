import React, { useImperativeHandle } from "react";
import * as THREE from "three";
import { useThree } from '@react-three/fiber';

const GetMouseCanvasCoords = React.forwardRef((props, ref) => {
  const {
    groundPlaneName="ShadowGroundPlane"
  } = props;
  const { mouse } = useThree();
  const camera = useThree(state => state.camera);
  const scene = useThree(state => state.scene);
  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();

  useImperativeHandle(ref, () => ({
    getMouseDownCanvasCoords: () => {
      pointer.x = mouse.x;
      pointer.y = mouse.y;
      raycaster.setFromCamera( pointer, camera );
      let shadowGroundPlane = scene.children.filter(e => e.name === groundPlaneName)[0];
      let intersection = raycaster.intersectObject(shadowGroundPlane, false);
      let result = {x: null, y: null, z: null};
      if (intersection.length > 0) {
        // console.log("getMouseDownCanvasCoords");
        // console.log(intersection);
        result.x = intersection[0].point.x;
        result.y = intersection[0].point.y;
        result.z = intersection[0].point.z;
      }
      return result;
    },
  }));

  return(
    <></>
  )
});

export default GetMouseCanvasCoords;