import React from "react";

import Button from 'react-bootstrap/Button';
import {Fullscreen, FullscreenExit} from "react-bootstrap-icons";

function ActionMenuButton({fullScreenHandle=function(){}}) {
  return(
    <Button variant="secondary"
      className="has-icon"
      style={{padding: ".3rem"}}
      onClick={fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter}
    >
      <Fullscreen fontSize="1.5rem" style={{display: fullScreenHandle.active ? "none" : "block"}} className="no-print" />
      <FullscreenExit fontSize="1.5rem" style={{display: fullScreenHandle.active ? "block" : "none"}} className="no-print" />
    </Button>
    
  );
}

export default ActionMenuButton